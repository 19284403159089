<template>
  <SidePopup
    :title="title"
    v-on:close="onClose"
    @submit="onSubmit"
    @change-tab="onChangeTab"
    :loading="loading"
    :tabs="tabs"
    :tabStart="tabStart"
    routeback="/fornecedor/comercial/politicas-desconto"
    :closeOnOutsideClick="!loading"
  >
    <template v-slot:form>
      <Grid grid-template-columns="repeat(3, 1fr)">
        <FormField
          label="Nome"
          v-model="form.nome"
          :validation="$v.form.nome"
        />
        <FormField label="Código interno" v-model="form.id_politica_erp" />
        <FormField
          label="Faixa decorativa "
          v-model="form.label"
          :validation="$v.form.label"
          hint="Define o que vem escrito dentro da faixa de promoção. (Limite de 15 caracteres)"
        />
        <FormSelect
          label="Cor da faixa"
          v-model="form.cor"
          :options="optionsCores"
          :validation="$v.form.cor"
          messages="Define a cor de fundo da faixa de promoção desta politica de desconto."
        />

        <FormDatetimePicker
          dense
          v-model="form.datainicial"
          label="Data inicial"
          :validation="$v.form.datainicial"
        />

        <FormDatetimePicker
          dense
          v-model="form.datafinal"
          label="Data final"
          :validation="$v.form.datafinal"
        />
        <FormSelect
          label="Tipo"
          v-model="form.tipoPolitica"
          :options="tipoPoliticaOptions"
          :validation="$v.form.cor"
        />
        <FormField
          v-show="form.tipoPolitica == '%'"
          label="Fator preço"
          v-model="form.fator"
          :hint="priceFactorTip"
          :money="variantMaskPriceFactor(form.fator)"
          :validation="$v.form.fator"
        />
        <FormField
          v-show="form.tipoPolitica == 'R$'"
          label="Preço fixo"
          v-model="form.valor"
          :money="moneyMask"
          :validation="$v.form.valor"
        />
        <FormSelect
          v-if="form.tipoPolitica == 'P'"
          label="Tipo de progressão"
          v-model="form.descontoProgressivo.tipoPolitica"
          :options="tipoProgressaoOptions"
          :validation="$v.form.cor"
        />
      </Grid>
      <Grid grid-template-columns="repeat(3, 1fr)">
        <FormCheckbox
          label="Mostrar preço original"
          v-model="form.mostrarPrecoOriginal"
        />
        <FormCheckbox
          label="Aplicar à todos os clientes"
          v-model="useAllClientes"
          messages="Quando marcado a politica aparecerá para todos clientes logados, e será removido todos os vínculos com clientes adicionados"
        />
        <FormCheckbox
          label="Aplicar em modo de delivery"
          v-model="form.delivery"
        />
        <FormCheckbox
          label="Aplicar em modo de retirada"
          v-model="form.retirada"
        />
        <FormCheckbox
          v-if="form.tipoPolitica != 'P'"
          :disabled="!enableVendaPlus"
          label="Aplicação manual para o Venda+"
          v-model="form.aplica_manual"
          messages="Quando marcado a politica só poderá ser adicionada manualmente pelo atendente ao utilizar o aplicativo do Venda+"
        />
        <FormCheckbox
          label="Ignorar fator de preço para formas de pagamento"
          v-model="form.ignorar_fator_forma_pagamento"
          messages="Quando marcado a politica irá ignorar o fator de preço de qualquer forma de pagamento"
        />
        <FormCheckbox
          label="Permitir que a integração modifique os dados da faixa"
          v-model="form.permitirAlteracaoFaixa"
        />
      </Grid>
      <FaixasDesconto :form="form" @normalize-price="normalizePricefactor" />
      <div v-if="tagsGerais" class="alert-info">
        <label style="flex: 1;align-self: center;">
          Essa política foi criada pela sua integração
        </label>
        <FormButton text="Detalhes" @click="showDetails" />
      </div>
      <FormCheckbox
        v-if="form.idpolitica"
        label="Salvar modificações nos clientes e produtos nessa edição"
        v-model="form.permitirEditarVinculos"
        messages="Quando essa opção estiver desmarcada os vinculos com cliente e produto não serão alterados. Caso queira alterar somente os dados acima, essa opção pode ser desmarcada."
      />
      <div v-if="!useAllClientes && initialized">
        <LineDivider>
          <h4>Clientes</h4>
        </LineDivider>
        <SelectListComponent
          v-model="addedClients"
          :addAllItems="clientsToAdd"
          :disableButton="loading"
          :searchEndpoint="clientesSearchEndpoint"
          :loadEndpoint="clientesSearchEndpoint"
          :searchTypes="clientesSearchTypes"
          :addAllEndpoint="clientesSearchEndpoint"
          :itemOptions="clientOptions"
          :showSearchList="false"
          :showAddedListSearch="isUpdating"
          @fetching="onFetching"
          @add-all-items="addAllClientes"
          @change="onChangeList"
        />
      </div>
      <div v-if="initialized">
        <LineDivider>
          <h4>Produtos</h4>
        </LineDivider>
        <SelectListComponent
          v-model="addedProducts"
          :addAllItems="productsToAdd"
          :disableButton="loading"
          :disableOptions="disableOptions"
          :searchEndpoint="produtosSearchEndpoint"
          :searchEndpointParams="searchProdutosEndpointParams"
          :loadEndpoint="produtosLoadEndpoint"
          :addAllEndpoint="produtosAddAllEndpoint"
          :searchTypes="produtosSearchTypes"
          :itemOptions="produtosOptions"
          :showSearchList="false"
          :showAddedListSearch="isUpdating"
          @remove-all="removeAllProducts"
          @fetching="onFetching"
          @remove-itens="removeItens"
          @add-all-items="addAllProdutos"
          @change="onChangeList"
        />
      </div>
    </template>
    <template v-slot:info>
      <DetalhesPoliticaDesconto
        :tagsGerais="tagsGerais"
        :tagsProdutos="tagsProdutos"
        :tagsCliente="tagsCliente"
      />
    </template>
    <template v-slot:cancel v-if="showDetalhes">
      <FormButton text="Voltar" type="perigo" @click="hideDetails" />
    </template>
    <template v-slot:buttons v-if="!showDetalhes">
      <FormButton text="Salvar" submit type="submissao" />
    </template>
  </SidePopup>
</template>

<script>
import SidePopup from '@/components/SidePopup'
import Grid from '@/components/Grid'
import FormButton from '@/components/form/FormButton'
import FormField from '@/components/form/FormField'
import FormSelect from '@/components/form/FormSelect'
import FormDatetimePicker from '@/components/form/FormDatetimePicker'
import FormCheckbox from '@/components/form/FormCheckbox'
import LineDivider from '@/components/LineDivider'
import SelectListComponent from '@/components/select-list/SelectListComponent'
import FaixasDesconto from './FaixasDesconto'
import DetalhesPoliticaDesconto from './DetalhesPoliticaDesconto'
import mixinUtils from '@/mixins/utils'

import { mapGetters } from 'vuex'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { COLOR_OBJECT, COLOR_Array } from '../../../utils/colors'
import { getFormattedDate } from '../../../utils/datetime'
import {
  POLITICA_DESCONTO_LOAD,
  POLITICA_DESCONTO_SAVE,
  POLITICA_DESCONTO_SAVE_ITENS
} from '@/store/actions/politicaDesconto'
import { PRODUTOS_LOAD_BY_COD_INTERNO } from '@/store/actions/produto'
import * as _ from 'lodash'
import moment from 'moment'
import { ModulesEnum } from '@/store/actions/modulesEnum'

export default {
  name: 'PoliticaDescontoForm',
  mixins: [mixinUtils],
  components: {
    SidePopup,
    FormButton,
    LineDivider,
    Grid,
    FormField,
    FormSelect,
    FaixasDesconto,
    FormCheckbox,
    SelectListComponent,
    FormDatetimePicker,
    DetalhesPoliticaDesconto
  },
  validations: {
    form: {
      nome: { required },
      label: { required },
      nivel: { required },
      datainicial: { required },
      datafinal: { required },
      fator: {
        required: requiredIf(function() {
          return this.form.tipoPolitica === '%'
        })
      },
      valor: {
        required: requiredIf(function() {
          return this.form.tipoPolitica === 'R$'
        })
      }
    }
  },
  data: () => ({
    mode: 'add',
    title: 'Editar politica de desconto',
    form: {
      idpolitica: null,
      id_politica_erp: null,
      nome: null,
      metadados: null,
      label: null,
      nivel: 10,
      aplica_manual: false,
      ignorar_fator_forma_pagamento: false,
      datainicial: null,
      datafinal: null,
      fator: '',
      valor: '',
      descontoProgressivo: {
        tipoPolitica: '%',
        politicas: [
          {
            fator: 0,
            valor: 0,
            quantidade: 0
          }
        ]
      },
      tipoPolitica: '%',
      mostrarPrecoOriginal: false,
      cor: COLOR_OBJECT.red.value,
      delivery: true,
      retirada: true,
      produtos: [],
      permitirAlteracaoFaixa: true,
      permitirEditarVinculos: true,
      erros: {
        descontoProgressivo: {}
      }
    },
    tabStart: 0,
    showDetalhes: false,
    loading: false,
    initialized: false,
    products: [],
    addedProducts: [],
    addedProductsSimple: [],
    addedClients: [],
    colors: COLOR_Array,
    search: {
      option: 'nomeProduto',
      value: ''
    },
    listHeight: '400px',
    dateFormat: 'DD/MM/YYYY HH:mm:ss',
    currentPage: 0,
    startPos: 0,
    disableOptions: {
      key: 'id_produto_erp',
      text: 'Não é possível adicionar produtos que não possuem código interno.'
    },
    useAllClientes: true,
    clientesSearchTypes: [
      { text: 'Nome', value: 'nomeCliente', search: '' },
      { text: 'Email', value: 'email', search: '' },
      { text: 'Cpf', value: 'cpf', search: '' }
    ],
    produtosSearchTypes: [
      {
        text: 'Produto',
        value: 'nomeProduto',
        search: ''
      },
      {
        text: 'Categoria',
        value: 'categoria',
        search: ''
      },
      {
        text: 'Cod. de barras',
        value: 'codigoBarras',
        search: ''
      },
      {
        text: 'Cod. interno',
        value: 'codigoInterno',
        search: ''
      },
      {
        text: 'Cod. agrupador',
        value: 'codigoAgrupador',
        search: ''
      }
    ],
    clienteSelectedItems: null,
    tipoPoliticaOptions: [
      {
        text: 'Fator de preço (%)',
        value: '%'
      },
      {
        text: 'Preço fixo - Ofertas',
        value: 'R$'
      },
      {
        text: 'Desconto Progressivo',
        value: 'P'
      }
    ],
    tipoProgressaoOptions: [
      {
        text: 'Fator de preço (%)',
        value: '%'
      },
      {
        text: 'Preço fixo',
        value: 'R$'
      }
    ],
    moneyMask: {
      decimal: ',',
      thousands: '',
      prefix: '',
      suffix: '',
      default: '',
      precision: 4,
      masked: false
    },
    productsToAdd: [],
    clientsToAdd: [],
    tabs: [
      {
        key: 'form',
        text: 'Formulário'
      },
      {
        key: 'info',
        text: 'Detalhes'
      }
    ],
    variantPrecision: 4
  }),
  computed: {
    ...mapGetters([
      'getFornecedorId',
      'getFornecedorTag',
      'moduloFornecedorAtivo'
    ]),
    clientesSearchEndpoint() {
      return `api/v4/client/${this.getFornecedorId}/search/options`
    },
    clientOptions() {
      return { key: 'nome', id: 'idcliente', payloadProp: 'idcliente' }
    },
    produtosSearchEndpoint() {
      return `api/v4/products/${this.getFornecedorId}`
    },
    searchProdutosEndpointParams() {
      return `&tipoProduto=SIMPLES`
    },
    produtosLoadEndpoint() {
      return `api/v4/products/erp/${this.getFornecedorId}`
    },
    produtosAddAllEndpoint() {
      return `api/v4/products/erp-all/${this.getFornecedorId}`
    },
    produtosOptions() {
      return {
        key: 'nome',
        id: 'id_produto_erp',
        payloadProp: 'ids',
        obs: ['id_produto_erp', 'agrupador_embalagem', 'descricao_embalagem']
      }
    },
    optionsCores() {
      return this.colors
    },
    searchOptions() {
      return {
        nomeProduto: {
          name: 'Produto',
          value: 'nomeProduto'
        },
        categoria: {
          name: 'Categoria',
          value: 'categoria'
        },
        codigoBarras: {
          name: 'Cod. de barras',
          value: 'codigoBarras'
        },
        codigoInterno: {
          name: 'Cod. interno',
          value: 'codigoInterno'
        },
        codigoAgrupador: {
          name: 'Cod. agrupador',
          value: 'codigoAgrupador'
        }
      }
    },
    priceFactorTip() {
      return (
        'O fator preço irá determinar quanto do valor original deverá valer este produto. Exemplo: <br>' +
        'Produto A - R$ 10,00 <br>' +
        'Fator preço - 0,5 (50% do valor original)<br>' +
        'Cálculo:<br>' +
        'Produto A x Fator Preço = Preço do produto com desconto<br>' +
        'R$10,00 x 0,5 = R$ 5,00 este será o preço do produto dentro da vigência desta política.<br>'
      )
    },
    enableVendaPlus() {
      return this.showComponente(
        ModulesEnum.VENDAMAIS_APP,
        ModulesEnum.VENDAMAIS_WEB
      )
    },
    computedDescontoProgressivo() {
      return _.get(this, 'form.descontoProgressivo') || {}
    },
    utilPromoManuais() {
      return _.get(this.form.metadados, 'util_promo_manuais')
    },
    tagsGerais() {
      return this.utilPromoManuais && this.utilPromoManuais.tags_gerais
    },
    tagsCliente() {
      return this.utilPromoManuais && this.utilPromoManuais.tags_cliente
    },
    tagsProdutos() {
      return this.utilPromoManuais && this.utilPromoManuais.tags_produtos
    },
    isUpdating() {
      return this.form && this.form.idpolitica ? true : false
    }
  },
  watch: {
    'form.descontoProgressivo.politicas': {
      deep: true,
      handler(newVal) {
        if (newVal) {
          const politicas = newVal.map((val, i) => ({
            fator: this.normalizarPonto(
              val.fator,
              this.form.descontoProgressivo.politicas[i].fator
            ),
            valor: this.normalizarPonto(
              val.valor,
              this.form.descontoProgressivo.politicas[i].valor
            ),
            quantidade: this.normalizarPonto(
              val.quantidade.replace(' ', ''),
              this.form.descontoProgressivo.politicas[i].quantidade
            )
          }))
          if (!_.isEqual(this.form.descontoProgressivo.politicas, politicas)) {
            this.form.descontoProgressivo.politicas = politicas
          }
        }
      }
    },
    addedProducts(val) {
      this.addedProductsSimple = (val || [])
        .filter(v => !!v.id_produto_erp)
        .map(p => p.id_produto_erp)
    }
  },
  created() {
    let idRoute = this.$route.params.id
    if (idRoute !== 'novo') {
      this.mode = 'edit'
      this.getData(idRoute)
    } else {
      this.initialized = true
    }
  },
  methods: {
    getData(id) {
      this.loading = true
      this.$store
        .dispatch(POLITICA_DESCONTO_LOAD, id)
        .then(resp => {
          this.loadData(resp)
        })
        .catch(() => (this.loading = false))
    },
    loadData(discountPolicy) {
      if (!discountPolicy) return
      this.form.permitirEditarVinculos = false
      const quando = discountPolicy.quando
      const entao = discountPolicy.entao
      const label = entao.find(v => v.key === 'anotacao_destaque')
      const indicePreco = entao.find(v => v.key === 'indice_preco')
      const precoFixo = entao.find(v => v.key === 'preco_fixo')
      const descontoProgressivo = entao.find(
        v => v.key === 'desconto_progressivo'
      )
      const vigencia_inicial = quando.find(v => v.nome === 'vigencia_inicial')
      const vigencia_final = quando.find(v => v.nome === 'vigencia_final')
      const cor = entao.find(v => v.key === 'cor_destaque')
      const mostrarPrecoOriginal = entao.find(
        v => v.key === 'mostrar_preco_original'
      )
      const clienteSelecionado = _.get(discountPolicy, 'clientes', [])
      if (clienteSelecionado && clienteSelecionado.length > 0) {
        this.addedClients = clienteSelecionado.map(item => {
          return {
            idcliente: item
          }
        })
        this.useAllClientes = false
      } else {
        this.addedClients = null
        this.useAllClientes = true
      }
      this.form.nome = discountPolicy.metadados.descricao
      this.form.metadados = discountPolicy.metadados
      this.form.idfornecedor =
        discountPolicy.idfornecedor || this.getFornecedorId
      this.form.idpolitica = discountPolicy.idpolitica
      this.form.id_politica_erp = discountPolicy.id_politica_erp
      this.form.nivel = discountPolicy.nivel || 10
      this.form.label = _.get(label, 'value', null)
      this.form.datainicial = this.formatData(
        _.get(vigencia_inicial, 'valor', '')
      )
      this.form.datafinal = this.formatData(_.get(vigencia_final, 'valor', ''))

      const regexIndicePreco = /(?<=\.)\w+/.exec(
        _.get(indicePreco, 'value', '0.0000')
      )
      const casasDepoisVirgula = _.get(regexIndicePreco, '0', 4)
      this.variantPrecision = casasDepoisVirgula.length

      this.form.fator = this.prepareNumberDecimalToString(
        _.get(indicePreco, 'value', null),
        this.variantPrecision
      )
      const valor = _.get(precoFixo, 'value', null)
      this.form.valor = this.prepareNumberDecimalToString(
        typeof valor === 'string' ? valor.replace(' ', '') : valor,
        4
      )
      this.form.delivery = discountPolicy.delivery === false ? false : true
      this.form.retirada = discountPolicy.retirada === false ? false : true
      this.form.aplica_manual = discountPolicy.aplica_manual || false
      this.form.ignorar_fator_forma_pagamento =
        discountPolicy.ignorar_fator_forma_pagamento || false
      this.form.permitirAlteracaoFaixa = !(
        discountPolicy.ignorar_alteracao_erp || []
      ).includes('faixa')

      if (descontoProgressivo) {
        this.form.tipoPolitica = 'P'
        this.form.aplica_manual = false
      } else if (this.form.valor) {
        this.form.tipoPolitica = 'R$'
      } else if (!this.form.tipoPolitica) {
        this.form.tipoPolitica = '%'
      }

      if (descontoProgressivo) {
        const politicasValues = Object.values(
          _.get(descontoProgressivo, 'value.politicas')
        )
        const politicas = Object.entries(
          _.get(descontoProgressivo, 'value.politicas') || {}
        )
        this.form.descontoProgressivo = {
          tipoPolitica:
            _.get(politicasValues, '[0].key', '') === 'preco_fixo' ? 'R$' : '%',
          politicas:
            this.form.tipoPolitica === 'P'
              ? politicas.map(([key, value]) => {
                  const valor = this.prepareNumberDecimalToString(
                    value.value,
                    4
                  )
                  return {
                    quantidade: +key.replace(' ', ''),
                    fator: valor,
                    valor
                  }
                })
              : []
        }
      }
      this.form.mostrarPrecoOriginal =
        _.get(mostrarPrecoOriginal, 'value', false) === true ? true : false

      this.form.cor = _.get(cor, 'value', null)

      if (discountPolicy.keys) {
        discountPolicy.keys.forEach(k => {
          if (k && k.iDProdutoErp) {
            this.form.produtos.push(k.iDProdutoErp)
          }
        })
      }

      this.form.produtos = _.uniq(this.form.produtos)
      this.addedProducts = this.form.produtos.map(p => ({
        id_produto_erp: p
      }))
      this.loading = false
      this.initialized = true
    },
    normalizarPonto(newVal, oldVal) {
      return newVal || oldVal
    },
    normalizePricefactor() {
      if (this.form.fator < 0) {
        this.form.fator = null
      }
      if (this.form.valor < 0) {
        this.form.valor = null
      }
    },
    mapProductsObs(products) {
      function nullToString(e) {
        return !e ? e : ''
      }
      return (products || []).map(function(p) {
        return {
          ...p,
          obs: [
            nullToString(p.id_produto_erp),
            nullToString(p.agrupador_embalagem),
            nullToString(p.descricao_embalagem)
          ].filter(e => !!e)
        }
      })
    },
    formatDatetime(date) {
      return date ? moment(date).format(this.dateFormat) : ''
    },
    formatData(date) {
      if (!date) return
      return getFormattedDate(date, false, true)
    },
    addAllProdutos(newItems, ids) {
      if (ids) {
        let array = _.unionWith(this.form.produtos, ids)
        this.form.produtos = _.uniq(array)
        this.getProductsByIdsErp(true)
      }
    },
    removeItens(itens) {
      const ids = itens.map(i => i.id_produto_erp)
      this.form.produtos = this.form.produtos.filter(p => ids.indexOf(p) < 0)
      this.addedProducts = this.addedProducts.filter(p => ids.indexOf(p) < 0)
    },
    removeAllProducts() {
      this.form.produtos = []
      this.addedProducts = []
    },
    addAllClientes(allItems, newItems) {
      let newValues = (allItems || []).concat(newItems)
      let keyid = _.get(this.clientOptions, 'id', null)
      let key = _.get(this.clientOptions, 'key', null)
      newValues = _.uniqBy(newValues, keyid)
      this.addedClients = newValues.filter(o => o[key])
      this.clientsToAdd = this.addedClients
    },
    getProductsByIdsErp(all = false) {
      if (!_.get(this.form, 'produtos.length', 0)) return
      const endPos = this.startPos + 20
      const nProducts = this.form.produtos.slice(this.startPos, endPos)
      if (!_.get(nProducts, 'length', 0)) return
      const payload = { ids: nProducts, page: 0 }
      this.$store
        .dispatch(PRODUTOS_LOAD_BY_COD_INTERNO, payload)
        .then(result => {
          const length = _.get(result, 'length', 0)
          if (!length) return
          this.startPos += length
          this.updateAddedProducts(result, all)
        })
        .catch(this.notifyError)
    },
    updateAddedProducts(newProducts, all) {
      this.addedProducts = (this.addedProducts || []).concat(newProducts)

      this.addedProducts = _.uniqBy(this.addedProducts, 'idproduto')
      this.addedProducts = this.mapProductsObs(this.addedProducts).map(p => {
        return {
          ...p,
          selected: false,
          bgColor: ''
        }
      })
      if (all) {
        this.productsToAdd = this.addedProducts
      }
    },
    getDateConfig(date) {
      return {
        dayViewHeaderFormat: 'MMMM YYYY',
        format: 'DD/MM/YYYY HH:mm:ss',
        showTodayButton: false,
        showClear: false,
        showClose: false,
        useCurrent: true,
        sideBySide: true,
        locale: moment.locale('pt-br'),
        defaultDate: date.format()
      }
    },
    onFetching(val) {
      this.changeLoading(val || false)
    },
    onChangeList() {
      this.form.permitirEditarVinculos = true
    },
    validateForm(form) {
      let valid = true
      let err = ''
      if (!form.produtos || form.produtos.length <= 0) {
        err = 'Adicione pelo menos um produto'
        this.$vueOnToast.pop('error', err)
        return false
      }
      if (
        form.rawDatainicial === form.rawDatafinal ||
        moment(form.rawDatainicial).isAfter(form.rawDatafinal)
      ) {
        err = 'A data inicial não pode ser maior ou igual à data final'
        this.$vueOnToast.pop('error', err)
        return false
      }
      if (form.tipoPolitica === 'P') {
        const { politicas, tipoPolitica } = form.descontoProgressivo
        politicas.forEach(politica => {
          if (tipoPolitica === '%' && politica.fator <= 0) {
            err = 'Informe o fator de preço da política de desconto'
            valid = false
          } else if (tipoPolitica === 'R$' && politica.valor <= 0) {
            err = 'Informe o preço fixo da política de desconto'
            valid = false
          }
          if (!isNaN(Number(politica.quantidade)) && politica.quantidade < 0) {
            err = 'Informe a quantidade mínima da política de desconto'
            valid = false
          }
        })
        const setQuantidade = new Set(politicas.map(p => p.quantidade))
        if (setQuantidade.size !== form.descontoProgressivo.politicas.length) {
          err =
            'Por favor verifique as quantidades informadas para as faixas de desconto, uma das quantidades está repetida'
          valid = false
        }
        if (err) {
          this.$vueOnToast.pop('error', err)
        }
        return valid
      } else {
        if (form.fator && form.fator <= 0) {
          err = 'O fator de preço deve ser maior que zero.'
          valid = false
        } else if (form.valor && form.valor <= 0) {
          err = 'O valor do desconto deve ser maior que zero.'
          valid = false
        }
        if (err) {
          this.$vueOnToast.pop('error', err)
        }
      }
      return valid
    },
    mapClientes() {
      return (this.addedClients || []).map(item => {
        return item.idcliente
      })
    },
    getIgnorarAlteracaoErp() {
      const ignorarAlteracaoErp = []
      if (!this.form.permitirAlteracaoFaixa) {
        ignorarAlteracaoErp.push('faixa')
      }
      return ignorarAlteracaoErp || []
    },
    prepareProducts() {
      if (this.addedProductsSimple.length) {
        let array = _.unionWith(this.form.produtos, this.addedProductsSimple)
        this.form.produtos = _.uniq(array)
      }
    },
    prepareDataToSubmit(p) {
      const clientes = this.useAllClientes
        ? null
        : _.uniq(this.mapClientes(this.addedClients))
      const isFator = this.form.tipoPolitica === '%'
      const isPrecoFixo = this.form.tipoPolitica === 'R$'
      this.form.fator = isFator ? this.form.fator : null
      this.form.valor = isPrecoFixo ? this.form.valor : null
      this.form.descontoProgressivo =
        this.form.tipoPolitica === 'P' ? this.form.descontoProgressivo : null
      const data = {
        ...this.form,
        id_politica_erp: this.form.id_politica_erp
          ? this.form.id_politica_erp
          : '',
        fator: this.form.fator && this.form.fator.replace(',', '.'),
        valor:
          this.form.valor && this.form.valor.replace(',', '.').replace(' ', ''),
        rawDatainicial: this.form.datainicial,
        rawDatafinal: this.form.datafinal,
        datainicial: this.formatDatetime(this.form.datainicial),
        datafinal: this.formatDatetime(this.form.datafinal),
        aplica_manual:
          !isFator && !isPrecoFixo ? false : this.form.aplica_manual,
        ignorar_alteracao_erp: this.getIgnorarAlteracaoErp(),
        metadados: {
          ...this.form.metadados,
          idfornecedor: this.form.idfornecedor,
          descricao: this.form.nome,
          manipuladoManualmente: this.tagsGerais ? true : false
        },
        produtos: p,
        clientes,
        increment: true
      }
      return data
    },
    onSubmit() {
      this.prepareProducts()
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      const produtos = _.uniq(this.form.produtos)
      const chunkSize = 5000
      const isLarge = produtos && produtos.length > chunkSize
      let currentProductSize = chunkSize
      let p = isLarge ? produtos.slice(0, chunkSize) : produtos
      const data = this.prepareDataToSubmit(p)
      if (this.validateForm(data)) {
        this.loading = true
        const shouldSendChuncks = isLarge && this.form.permitirEditarVinculos
        this.$store
          .dispatch(POLITICA_DESCONTO_SAVE, data)
          .then(async result => {
            if (shouldSendChuncks && result) {
              while (currentProductSize <= produtos.length) {
                currentProductSize += chunkSize
                await this.handleChunk(
                  { result, data, produtos },
                  chunkSize,
                  currentProductSize
                )
              }
              this.notifySucess(this.isUpdating)
            } else {
              this.notifySucess(this.isUpdating)
            }
          })
          .catch(this.notifyError)
      }
    },
    handleChunk({ result, data, produtos }, start, end) {
      return new Promise((resolve, reject) => {
        data = {
          ...data,
          idpolitica: result.idpolitica || result.item_id,
          produtos: produtos.slice(start, end)
        }
        this.$store
          .dispatch(POLITICA_DESCONTO_SAVE_ITENS, data)
          .then(resolve)
          .catch(err => {
            reject(err)
          })
      })
    },
    changeLoading(val) {
      this.loading = val
    },
    notifyError(err) {
      const msg = _.get(err, 'responseJSON.Mensagem', 'Erro ao buscar produtos')
      this.$vueOnToast.pop('error', msg)
      this.changeLoading(false)
    },
    notifySucess(isUpdating) {
      let msg = isUpdating
        ? 'Política de desconto alterada com sucesso'
        : 'Política de desconto cadastrada com sucesso'
      this.$vueOnToast.pop('success', msg)
      this.changeLoading(false)
      this.onClose(true)
    },
    onClose(routeback = false) {
      this.$emit('close')
      if (routeback) {
        this.$router.replace('/fornecedor/comercial/politicas-desconto')
      }
    },
    onChangeTab(tabIndex) {
      tabIndex ? this.showDetails() : this.hideDetails()
    },
    showDetails() {
      this.showDetalhes = true
      this.tabStart = 1
    },
    hideDetails() {
      this.showDetalhes = false
      this.tabStart = 0
    },
    variantMaskPriceFactor() {
      return {
        decimal: ',',
        thousands: '',
        prefix: '',
        suffix: '',
        default: '',
        precision: this.variantPrecision,
        masked: false
      }
    },
    showComponente(modulos = []) {
      if (modulos && modulos.length > 0) {
        return this.moduloFornecedorAtivo(modulos)
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-info {
  display: flex;
  border: solid 2px #ccc;
  padding: 6px 10px;
  background: white;
  color: gray;
  border-radius: 15px;
  margin-top: 20px;
}
</style>
