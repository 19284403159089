<template>
  <div class="detalhes-politca-desconto">
    <div class="detalhes-content">
      <div class="detalhes-header text-center">
        <h3>Detalhes técnicos da política</h3>
      </div>
      <div v-if="tagsGerais" class="detalhes-body">
        <div class="detail-info">
          <small>
            As informações técnicas são informadas pela integração. Elas não
            refletem quaisquer modificações manuais.
          </small>
        </div>
        <div class="">
          <div v-if="tagsGerais" style="padding: 10px;">
            <LineDivider>
              <h4>Informações gerais</h4>
            </LineDivider>
            <Grid grid-template-columns="repeat(3, 1fr)">
              <div
                v-for="[key, value] in Object.entries(tagsGerais)"
                :key="key"
              >
                <span class="detail-key">{{ key }}:</span>
                <span class="detail-value">{{ value }}</span>
              </div>
            </Grid>
          </div>
          <div v-if="tagsProdutos" style="padding: 10px;">
            <LineDivider>
              <h4>Informações de produto</h4>
            </LineDivider>
            <Grid grid-template-columns="repeat(3, 1fr)">
              <div
                v-for="[key, value] in Object.entries(tagsProdutos)"
                :key="key"
              >
                <span class="detail-key">{{ key }}:</span>
                <span class="detail-value">{{ value }}</span>
              </div>
            </Grid>
          </div>
          <div v-if="tagsCliente" style="padding: 10px;">
            <LineDivider>
              <h4>Informações de cliente</h4>
            </LineDivider>
            <Grid grid-template-columns="repeat(3, 1fr)">
              <div
                v-for="[key, value] in Object.entries(tagsCliente)"
                :key="key"
              >
                <span class="detail-key">{{ key }}:</span>
                <span class="detail-value">{{ value }}</span>
              </div>
            </Grid>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="detail-info">
          <small>
            Nenhuma informação técnicas foi informada pela integração.
          </small>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LineDivider from '@/components/LineDivider'
import Grid from '@/components/Grid'

export default {
  name: 'DetalhesPoliticaDesconto',
  components: { LineDivider, Grid },
  props: {
    tagsGerais: Object,
    tagsProdutos: Object,
    tagsCliente: Object
  },
  data: function() {
    return {
      showDetalhes: false
    }
  },
  methods: {
    showDiscountPolicycDetails() {
      this.showDetalhes = true
    },
    onBack() {
      this.$emit('go-back')
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-info {
  opacity: 0.8;
  text-align: center;
}
.detail-key {
  font-weight: 500;
}
.detail-value {
  opacity: 0.8;
  margin-left: 5px;
  color: #3b76da;
}
</style>
