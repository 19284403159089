var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detalhes-politca-desconto"},[_c('div',{staticClass:"detalhes-content"},[_vm._m(0),(_vm.tagsGerais)?_c('div',{staticClass:"detalhes-body"},[_vm._m(1),_c('div',{},[(_vm.tagsGerais)?_c('div',{staticStyle:{"padding":"10px"}},[_c('LineDivider',[_c('h4',[_vm._v("Informações gerais")])]),_c('Grid',{attrs:{"grid-template-columns":"repeat(3, 1fr)"}},_vm._l((Object.entries(_vm.tagsGerais)),function(ref){
var key = ref[0];
var value = ref[1];
return _c('div',{key:key},[_c('span',{staticClass:"detail-key"},[_vm._v(_vm._s(key)+":")]),_c('span',{staticClass:"detail-value"},[_vm._v(_vm._s(value))])])}),0)],1):_vm._e(),(_vm.tagsProdutos)?_c('div',{staticStyle:{"padding":"10px"}},[_c('LineDivider',[_c('h4',[_vm._v("Informações de produto")])]),_c('Grid',{attrs:{"grid-template-columns":"repeat(3, 1fr)"}},_vm._l((Object.entries(_vm.tagsProdutos)),function(ref){
var key = ref[0];
var value = ref[1];
return _c('div',{key:key},[_c('span',{staticClass:"detail-key"},[_vm._v(_vm._s(key)+":")]),_c('span',{staticClass:"detail-value"},[_vm._v(_vm._s(value))])])}),0)],1):_vm._e(),(_vm.tagsCliente)?_c('div',{staticStyle:{"padding":"10px"}},[_c('LineDivider',[_c('h4',[_vm._v("Informações de cliente")])]),_c('Grid',{attrs:{"grid-template-columns":"repeat(3, 1fr)"}},_vm._l((Object.entries(_vm.tagsCliente)),function(ref){
var key = ref[0];
var value = ref[1];
return _c('div',{key:key},[_c('span',{staticClass:"detail-key"},[_vm._v(_vm._s(key)+":")]),_c('span',{staticClass:"detail-value"},[_vm._v(_vm._s(value))])])}),0)],1):_vm._e()])]):_c('div',[_vm._m(2)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detalhes-header text-center"},[_c('h3',[_vm._v("Detalhes técnicos da política")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detail-info"},[_c('small',[_vm._v(" As informações técnicas são informadas pela integração. Elas não refletem quaisquer modificações manuais. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detail-info"},[_c('small',[_vm._v(" Nenhuma informação técnicas foi informada pela integração. ")])])}]

export { render, staticRenderFns }