<template>
  <div class="faixas-desconto" v-if="form.tipoPolitica == 'P'">
    <LineDivider>
      <h4>Faixas de desconto</h4>
    </LineDivider>
    <div class="faixas-desconto-container">
      <div>
        <div
          v-for="(line, index) in politicas"
          :key="index"
          class="faixas-desconto-inputs"
        >
          <div class="quantidade">
            <FormField
              label="Quantidade mínima"
              class="form-control"
              type="number"
              id="quantidade"
              :money="qtdMask"
              v-model="line.quantidade"
            />
          </div>

          <div class="valor">
            <FormField
              v-show="tipoPolitica === '%'"
              label="Fator preço"
              class="form-control"
              type="number"
              id="fator"
              v-model="line.fator"
              :money="moneyMask"
              @blur="normalizePricefactor"
            />
            <FormField
              v-show="tipoPolitica === 'R$'"
              label="Preço fixo"
              class="form-control"
              type="number"
              id="valor"
              v-model="line.valor"
              :money="moneyMask"
              @blur="normalizePricefactor"
            />
          </div>

          <div class="action">
            <i
              class="fas fa-trash-alt font-gray remove-icon"
              @click="removeDescontoProgressivo(line)"
            ></i>
          </div>
        </div>
        <div class="faixas-desconto-footer">
          <FormButton
            text=" Adicionar faixa"
            type="submissao"
            @click="addDescontoProgressivo"
          />
        </div>
      </div>
      <div class="faixas-desconto-hint font-gray col-lg-4">
        <p>
          Utilize o recurso de faixas para configurar qual o fator de preço ou
          preço fixo à ser aplicado para cada quantidade.
        </p>
        <p>
          Essa configuração aplica o fator ou preço correspondente à quantidade
          alcançada.
        </p>
        <p>Cada faixa, ao ser aplicada, substitui as quantidades inferiores.</p>
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from 'lodash'
import LineDivider from '@/components/LineDivider'
import FormButton from '@/components/form/FormButton'
import FormField from '@/components/form/FormField'

export default {
  name: 'FaixasDesconto',
  components: { FormButton, FormField, LineDivider },
  props: {
    form: Object,
    validation: {
      type: Object,
      default: () => null,
      required: false
    }
  },
  data: function() {
    return {
      selectedColor: '#F9F9F9',
      itens: this.defaultItens,
      search: '',
      qtdMask: {
        decimal: ',',
        thousands: ' ',
        prefix: '',
        suffix: '',
        default: '',
        precision: 0,
        masked: false
      },
      moneyMask: {
        decimal: ',',
        thousands: ' ',
        prefix: '',
        suffix: '',
        default: '',
        precision: 4,
        masked: false
      }
    }
  },
  watch: {
    defaultItens() {
      this.itens = this.defaultItens
    }
  },
  computed: {
    computedDescontoProgressivo() {
      return _.get(this, 'form.descontoProgressivo') || {}
    },
    politicas() {
      return _.get(this.computedDescontoProgressivo, 'politicas') || []
    },
    tipoPolitica() {
      return this.computedDescontoProgressivo.tipoPolitica
    }
  },
  methods: {
    methodDescontoProgressivoError(index) {
      return _.get(this, `form.erros.descontoProgressivo.${index}`) || {}
    },
    addDescontoProgressivo() {
      this.form.descontoProgressivo.politicas.push({
        fator: 0,
        valor: 0,
        quantidade: 0
      })
    },
    removeDescontoProgressivo(line) {
      const index = this.form.descontoProgressivo.politicas.indexOf(line)
      this.form.descontoProgressivo.politicas.splice(index, 1)
    },
    normalizePricefactor() {
      this.$emit('normalize-price', null)
    }
  }
}
</script>

<style lang="scss" scoped>
.has-erro {
  color: red;
  border-color: red;
}
.faixas-desconto {
  .faixas-desconto-container {
    display: flex;
    justify-content: space-evenly;
    .faixas-desconto-inputs {
      display: flex;
      div {
        margin: 0 2px;
      }
    }
    .faixas-desconto-footer {
      margin-left: 10px;
    }
  }
  .remove-icon {
    cursor: pointer;
    padding: 15px 0;
  }
  .font-gray {
    font-size: 13px;
    color: #6a6c6f;
  }
}
@media (max-width: 1100px) {
  .list-group.vue-select-list {
    width: 66vw !important;
  }
}
</style>
