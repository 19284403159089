export const COLOR_OBJECT = {
  empty: {
    name: 'Sem cor',
    value: 'empty'
  },
  red: {
    name: 'Vermelho',
    value: 'red'
  },
  orange: {
    name: 'Laranja',
    value: 'orange'
  },
  yellow: {
    name: 'Amarelo',
    value: 'yellow'
  },
  olive: {
    name: 'Verde Limão',
    value: 'olive'
  },
  green: {
    name: 'Verde',
    value: 'green'
  },
  teal: {
    name: 'Turquesa',
    value: 'teal'
  },
  blue: {
    name: 'Azul',
    value: 'blue'
  },
  purple: {
    name: 'Roxo',
    value: 'purple'
  },
  violet: {
    name: 'Lilás',
    value: 'violet'
  },
  pink: {
    name: 'Rosa',
    value: 'pink'
  },
  brown: {
    name: 'Marrom',
    value: 'brown'
  },
  grey: {
    name: 'Cinza',
    value: 'grey'
  },
  black: {
    name: 'Preto',
    value: 'black'
  }
}
export const COLOR_Array = [
  {
    text: 'Sem cor',
    value: 'empty'
  },
  {
    text: 'Vermelho',
    value: 'red'
  },
  {
    text: 'Laranja',
    value: 'orange'
  },
  {
    text: 'Amarelo',
    value: 'yellow'
  },
  {
    text: 'Verde Limão',
    value: 'olive'
  },
  {
    text: 'Verde',
    value: 'green'
  },
  {
    text: 'Turquesa',
    value: 'teal'
  },
  {
    text: 'Azul',
    value: 'blue'
  },
  {
    text: 'Roxo',
    value: 'purple'
  },
  {
    text: 'Lilás',
    value: 'violet'
  },
  {
    text: 'Rosa',
    value: 'pink'
  },
  {
    text: 'Marrom',
    value: 'brown'
  },
  {
    text: 'Cinza',
    value: 'grey'
  },
  {
    text: 'Preto',
    value: 'black'
  }
]
